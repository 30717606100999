import { Error } from "@mui/icons-material"
import { useEffect } from "react"
import axios from "axios"
import { config } from "../Config"

const client = axios.create({
    baseURL:config.url.API_URL,
    json:true
})

const ErrorPage = () => {

    useEffect(()=>{
        getSessionData()
    }, [])


    const getSessionData = async () =>  {
        const params = new URLSearchParams(window.location.search);
        var id = params.get("session_id");
        console.log(id)
        await client.get(`checkout-session/${id}`).then((result) => {
            console.log(result.data)
            if (result.data.status == "complete"){
            }
        })
    }

    return (
        <div class="succ-container">
            <div class="success-container">
        <Error class="success-icon"/>
        <h1 className='err-h1'>Payement échoué</h1>
        <p className="success-p">Une erreur est survenue lors du payement<br/>Veuillez réessayer</p>
        <a href="/" class="cta-link">Retourner a la page d'acceuil</a>
        </div>
    </div>
    )
}

export default ErrorPage