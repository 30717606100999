import CelebrationIcon from '@mui/icons-material/Celebration';
import { useEffect } from 'react';
import axios from 'axios';
import { config } from '../Config';

const client = axios.create({
    baseURL:config.url.API_URL,
    json:true
})

const Success = () => {

    useEffect(()=>{
        getSessionData()
    }, [])

    const getSessionData = async () =>  {
        const params = new URLSearchParams(window.location.search);
        var id = params.get("session_id");
        await client.get(`checkout-session/${id}`).then((result) => {
            if (result.data.status == "complete"){
                console.log(result.data)

            }
        })
    }

    return (
<div class="succ-container">
    <div class="success-container">
        <CelebrationIcon class="success-icon"/>
        <h1 className='success-h1'>Payement réussi</h1>
        <p className='success-p'>Merci d'avoir réserver vos vacances sur notre site. <br/>Vous recevrez un mail de confirmation de payement sous peu.</p>
        <a href="/" class="cta-link">Retourner a la page d'acceuil</a>
        </div>
    </div>
    )
}

export default Success;