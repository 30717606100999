
import { Grid, TextField, Button, Card, CardContent, Typography, CardActions } from "@mui/material";
import {onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth"
import { ControlPointDuplicate, Google, Label } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { auth, db } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth"
import MyDatePicker from "../Components/DatePick";
import { collection, addDoc, serverTimestamp, orderBy, query, onSnapshot, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import { ConfirmationNumber } from "@mui/icons-material";
import AlertDialog from "../Components/ConfirmDialog";
import { formatDate, getCardColor, getColor, getDates, getStatusColor, isPaid } from "../helper/helper";
import axios from "axios";
import AdminDatePicker from "../Components/AdminDatePicker";
import { config } from "../Config";
import Price from "../Components/Prices";
import { useNavigate } from "react-router-dom";
import { async } from "@firebase/util";

const client = axios.create({
    baseURL:config.url.API_URL,
    json:true
})


export const Dashboard = () => {
    const [error, setError] = useState();
    const [logged, setLogged] = useState(false);
    const [posts, setPosts] = useState([])
    const [blockedDates, setBlockedDates] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [paymentStatus, setPaymentStatus] = useState({});
    const[token, setToken] = useState();
    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate()

    useEffect(()=> {
        if (loading) return;
        if (!user){
            return navigate("/admin")
        }
        getBlockedDates();
        retrievePosts();



        // onAuthStateChanged(auth, (user) => {
        //     if (user){
        //         console.log("JE SUIS ENCORE LA ", user)
        //         setToken(false);
        //         getBlockedDates();
        //         retrievePosts();
        //         console.log(user)
        //     } else {
        //         navigate("/admin")
        //     }
        // })
        //if (loading) return;
        // if (user){
        //     setLogged(true)
        //     getBlockedDates();
        //     retrievePosts()
            
            

        // } else {
        //     setLogged(false)
        //     console.log("No one logged")
        // }
    }, [user, loading])


    const correctDates = (f_date, t_date) => {
        const array = getDates(f_date, t_date)
        for (let i = 0; i < blockedDates.length; i++) {
            for (let j = 0; j < array.length; j++) {      
                const comp = blockedDates[i].toString()
                if ( comp == array[j].toString() ){
                    return false
                }   
            }
        }
        return true;
    }


    const patchPost = async (id) => {
        const idToken = await user.getIdToken(true);
        const response = await client.patch(`/posts/${id}`,{valid:true},{headers:{Authorization: `Bearer ${idToken}`},})
            console.log("Post updated successfully:", response.data);
            // Here you can update your state or do something else
            if (response.data.status === "success"){
                const updatedPosts = posts.map(post => {
                    if (post._id === id) {
                      return { ...post, valid: true }
                    }
                    return post;
                  });
                  
                  setPosts(updatedPosts);
        }
    }

    const validatePost = async (post) => {
        console.log(post)
        const f_date = new Date(post.startDate)
        const t_date = new Date(post.endDate)
        if(!correctDates(f_date, t_date)){
            toast.error("Une réservation à déja été faites et la maison n'est pas disponnible a ces dates la", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:2000
               })
        } else {
            addDate(f_date, t_date)
            patchPost(post._id)
        }
    }

    // const deleteDate = async (from, to) => {
    //     const dates = getDates(new Date(from), new Date(to))

    // }

    const deletePost = async (post) => {
        const idToken = await user.getIdToken(true);
        const dates = getDates(new Date(post.startDate), new Date(post.endDate))

        const response = await client.delete(`/posts/${post._id}`,{headers:{Authorization: `Bearer ${idToken}`},})
        if (response.data.status === "success"){
            setPosts((prevState) => prevState.filter((p) => p._id !== post._id));
            console.log('Post deleted successfully:', response.data);

            toast.warning("Post supprimé", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:2000
               })
        }
            
            // update state or do something else
          
      };

    // DEPRECATED
    // const deleteDates = async (post, idToken) => {
    //     const dates = getDates(new Date(post.startDate), new Date(post.endDate))
    //     console.log("Deleting Date in front", idToken)
    //     const response = await client.delete(`/dates`,{data: {dates: dates}}, {headers:{Authorization: `Bearer ${idToken}`},})

    //       if (response.data.status === "success"){
    //         const updatedDates = blockedDates.filter(d => !dates.includes(d));
    //         setBlockedDates(updatedDates);
    //         console.log('Post deleted successfully:', response.data);
    //       } else{
    //         console.log("Response = ", response.data)
    //       }
    //       // update state or do something else
    
    // }

    const retrievePosts = async() => {
        
        try {
            const idToken = await user.getIdToken(true);
            console.log(idToken, "JE SUIS LAAAAA")

            await client.get("/posts", {headers:{Authorization: `Bearer ${idToken}`}}).then((response) => {
                setPosts(response.data)
            })

        
        } catch (error) {
            console.error(error);
        }
    }

    const getBlockedDates = async() => {
        client.get("/dates")
            .then(response => {
                if (response.data.status === "success") {
                const dates = response.data.data;
                console.log(`Retrieved ${dates.length} dates from the server:`, dates);
                // Do something with the dates array
                setBlockedDates(dates)            


                } else {
                console.error("Error retrieving dates:", response.data.errorMsg);
                }
            })
            .catch(error => {
                console.error("Error retrieving dates:", error);
            });
        
    }

    const sortPost = (value, posts) => {
        console.log("Je suis la",value)
        let sorted;
        switch (value) {
            case "valider":
                sorted = posts.sort((a,b)=>{
                    return b["valid"] - a["valid"]
                })
                break;
        
            case "wait":
                sorted = posts.sort((a,b)=>{
                    return a["valid"] - b["valid"]
                })
                break;
            
            case "dateD":
                sorted = posts.sort((a,b)=>{
                    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
                })
                break;
            
            case "dateC":
                sorted = posts.sort((a,b)=>{
                    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                })
                break;  

            default:
                break;
        }
        if (sorted){
            setPosts([...sorted])
        }
    }


    const updateDate = (f_date, s_date) => {
        setFromDate(f_date._d)
        setToDate(s_date._d)
        getBlockedDates()
    }

    const addDate = async (f_date, t_date) => {

        const all_dates = getDates(f_date, t_date)
        const idToken = await user.getIdToken(true);
        const response = await client.post("/dates",{all_dates}, {headers:{Authorization: `Bearer ${idToken}`},})
        if (response.data.status === "success"){
                toast.success("Les dates ont été bloqué", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
                   const newBlockedDates = [...blockedDates, ...all_dates];
                   setBlockedDates(newBlockedDates);
            } else {
                toast.error("Les dates n'ont pas pu être bloqué", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
            }
    }

     




    
    return (
        <div className="container">
            <ToastContainer/>
        <h2 className="text-2xl mt-32 font-medium">
        Dashboard
        </h2>

    <div className="row">
    <div className="col-md-8">
        {user ? <Price client={client} user={user}/> : <div/>}
        
    </div>


<div className="col-md-4">
<div className="shadow-xl mt-2 p-10 text-gray-700 rounded-lg">    
    <div>
        <div>
            <p className="text-center" style={{margin:"5px"}}>
            Bloquer manuellement pour les utilisateurs
            </p>
        </div>
        <AdminDatePicker data={updateDate} blocked={blockedDates} className="MyDatePicker"/>
        <Button onClick={()=>{addDate(fromDate, toDate)}}>Bloquer</Button>
        <Button onClick={()=>{addDate(fromDate, toDate)}}>Bloquer</Button>

    </div> 
</div>
</div> 
<div className="col-md-12">
<div className="row">
{posts.length !== 0 
?<div>  
<div className="flex justify-center mt-6">
<select id="countries" defaultValue={"valider"} onChange={e=>sortPost(e.target.value, posts)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
    <option value="default" disabled>Option de tri</option>
    <option value="valider">Réservation confirmé</option>
    <option value="wait">En attente</option>
    <option value="dateC">Du plus récent post au plus ancien</option>
    <option value="dateD">Du plus ancien post au plus récent</option>
    
</select>
 </div>
 <div className="card-label">
<span className="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span style={{ backgroundColor: "green" }} className={`flex w-2.5 h-2.5 rounded-full mr-1.5 flex-shrink-0`}></span>Réservation validées</span>
<span className="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span style={{ backgroundColor: "yellow" }} className={`flex w-2.5 h-2.5 rounded-full mr-1.5 flex-shrink-0`}></span>Réservation non validées</span>
<span className="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span style={{ backgroundColor: "orange" }} className={`flex w-2.5 h-2.5 rounded-full mr-1.5 flex-shrink-0`}></span>Réservation non validées mais chevauchante</span>

</div>
 <div className="flex flex-wrap">
{posts.map((post)=>(
    <div key={post.id} className="col-md-6 col-sm-12 col-lg-4">
        <div style={{boxShadow:`10px 10px 40px ${getCardColor(post, blockedDates)}`}} className={`shadow-2xl mb-4 mt-32 p-10 text-gray-700 rounded-lg myCard`}>
            <div className="flex justify-end mb-2">
                <span className="flex items-center text-sm font-medium text-gray-900 dark:text-white"><span style={{ backgroundColor: `${getStatusColor(post.paid)}` }} className={`flex w-2.5 h-2.5 rounded-full mr-1.5 flex-shrink-0`}></span>{post.paid ? "A payé" : "Payement en attente"}</span>
            </div>
            <p className="text-1xl text-left font-medium">
                    Nom : {post.name}
                </p>
            <p className="text-1xl text-left font-medium">
            Email : <a href={`mailto:${post.email}`} className="text-1xl text-left font-medium">
                {post.email}
            </a> 
            </p>
            
            <p className="text-1xl text-left font-medium">
                Du : { formatDate(post.startDate)}
            </p>   

            <p className="text-1xl text-left font-medium">
                Au : { formatDate(post.endDate)}
            </p>  

            <p className="text-1xl text-left font-medium">
                Status : { post.valid ? "Valider" : "En attente" }

            </p>  

            <hr className="flex mt-2 mb-2 justify-center">

            </hr>
            <p className="text-1xl text-center font-medium">
                Message : <br/> {post.question}
            </p>

            <div className="flex gap-4 justify-between action-card">
            <div className="text-red-600 flex items-center justify-center gap-2 py-2 text-sm">
                <AlertDialog content={"Une fois supprimé les dates seront libérées "} confirmFunction={deletePost} post={post} del={true}/>
            </div>

            {post.valid ? 
                <div/>
            :
                <div className="text-green-600 flex items-center justify-center gap-2 py-2 text-sm">
                    <AlertDialog content={"Une fois confirmé les dates seront bloquées pour les utilisateurs"} confirmFunction={validatePost} post={post} del={false} />
                </div>
            }
            
            </div>
        </div>

    </div>
      
))}
</div>
</div>
:
  <p className="text-center m-4">Aucun post...</p>
}
</div>
</div>

</div>
</div>
)
}