import { Button } from "@mui/material";
import { useEffect, useState } from "react"
import AddPriceDialog from "./AddPriceDialog";
import { toast } from "react-toastify";
import { formatDate } from "../helper/helper";
import { Check, Delete, Update, UpdateDisabled } from "@mui/icons-material";


const Price = ({user, client}) => {
    const [prices, setPrices] = useState([]);

    useEffect(()=> {
        retrievePrices();
    }, [])



    const addPrice = async (data) => {

        const Idtoken = await user.getIdToken(true);
        const response = await client.post("/prices",data, {headers:{Authorization: `Bearer ${Idtoken}`},})
        if (response.data.status === "success"){
                setPrices([...prices, response.data.data])
                toast.success("Nouveau prix enregistré", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
            } else {
                toast.error("Erreur", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
            }


    }


    const deletePrice = async(id) =>{
        try {
            const iDtoken = await user.getIdToken(true);
            const response = await client.delete(`/prices/${id}`, {headers:{Authorization: `Bearer ${iDtoken}`}})
            console.log(response.data)
            if (response.data.status === "success"){
                setPrices((prevState) => prevState.filter((p) => p._id !== id));
                toast.success("Prix supprimé avec succès", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
            }
            console.log(response)
        } catch (error) {
            console.error(error);
        }
    }

    const updatePrice = async(price) => {
        const Idtoken = await user.getIdToken(true);

        const response = await client.patch(`/prices/${price._id}`, {new: price} ,{headers:{Authorization: `Bearer ${Idtoken}`},})
            console.log("Price updated successfully:", response.data);
            // Here you can update your state or do something else
            console.log(response.data.data)
            if (response.data.status === "success"){
                const updatedPrices = prices.map(p => {
                    if (p._id === price._id) {
                      return response.data.data.updatedPrice 
                    }
                    return p;
                  });
                  
                  setPrices(updatedPrices);
                  toast.success("Modifié avec succès", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:2000
                   })
        }
    }

    const retrievePrices = async () => {
        try {
            const Idtoken = await user.getIdToken(true);
            console.log(Idtoken)

            const response = await client.get("/prices", {headers:{Authorization: `Bearer ${Idtoken}`}})
            setPrices(response.data)
            console.log(response)
        } catch (error) {
            console.error(error);
        }
        
    }


    return (
        <div className="mt-2 p-10 text-gray-700 rounded-lg">
            {prices.length === 0 ? (
                <div>
                    Chargement ...
                </div>
            ):(
                <div className="row">
                    {prices.map((price)=>(
                        <div class="col-md-4 prices p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <a href="#">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{price.name}</h5>
                            </a>
                            <p class="mb-1 font-normal text-gray-700 dark:text-gray-400">Du : {formatDate(price.startDate)}</p>
                            <p class="mb-1 font-normal text-gray-700 dark:text-gray-400">Au : {formatDate(price.endDate)}</p>
                            <p class="mb-1 font-normal text-gray-700 dark:text-gray-400">Prix : {price.price}€</p>
                           <div className="priceAction">
                                    <AddPriceDialog call={updatePrice} id={price._id} n={price.name} sd={price.startDate} ed={price.endDate} price={price.price} title={"Modification"} icon={<UpdateDisabled/>} openText={"Modifier"} componentStyle={"text-yellow-600 flex items-center justify-center gap-2 py-2 text-sm"} />                                
                                <div className="text-red-600 flex items-center justify-center gap-2 py-2 text-sm">
                                    <button onClick={()=>{deletePrice(price._id)}}>
                                        <div><Delete/> Supprimer</div>
                                    </button>
                                </div>

                            </div>
                           

                        </div>
                    ))}

                </div>
            )}
            <AddPriceDialog call={addPrice} id={null} title={"Ajout d'un nouveau prix"} icon={<Check/>} openText={"Nouveau"} componentStyle={"addPrice text-blue-600 flex items-center justify-center gap-2 py-2 text-sm"}/>
        </div>
    )
}

export default Price;