import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";

import './App.css';
import './Components/Navbar'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './pages/Home';
import Admin from './pages/Admin';
// TODO import Success 
import Success from './pages/Success';
import ErrorPage from './pages/Error';
import { Dashboard } from './pages/Dashboard';

function App() {

  return (

    <Router>
      <div className=''>

          <div className='App'>
            <Navbar/>
            <Routes>
                <Route exact path='/' element={<Home/>} />
                <Route path='/admin' element={<Admin/>} />
                <Route path='/success' element={<Success/>} />
                <Route path='/error' element={<ErrorPage/>} />
                <Route path='/dashboard' element={<Dashboard/>}/>
            </Routes>
          </div>
      </div>

      </Router>
    
  );
}

export default App;
